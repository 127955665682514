import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <section id="page404" className="page-section">
      <div className="container">
        <div className="row text-center">
          <div className="col">
            <h1>
              <FormattedMessage id="notfound.header" />
            </h1>
            <p>
              <FormattedMessage id="notfound.description" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default injectIntl(NotFoundPage)
